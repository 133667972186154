import { useEffect } from 'react';

export const useOutsideClick = (
  ref: React.MutableRefObject<null | HTMLElement>,
  outsideClickCallback: () => void,
  portalRef?: React.MutableRefObject<null | HTMLElement>
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref &&
        ref.current &&
        !ref?.current?.contains(event.target as Node) &&
        (portalRef
          ? portalRef?.current &&
            !portalRef.current.contains(event.target as Node)
          : true)
      ) {
        outsideClickCallback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, outsideClickCallback]);
};
