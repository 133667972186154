import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FuneralHomesResponse } from 'types/Sisfuner';

import { sisfuner } from 'services/sisfuner';

import { useLocalStorage } from 'hooks/useLocalStorage';

import { SelectItem } from 'components/shared';

import { useSession } from './AuthContext';

type funeralHomesUserType = {
  selectedFuneralHomesUser: string;
  funeralHomesOptions: SelectItem[];
  selectedFuneralData: FuneralHomesResponse;
  allFuneralHomesData: FuneralHomesResponse[];
};

interface InitialValueProps {
  funeralHomesUser: funeralHomesUserType;
  setFuneralHomesUser: (data: funeralHomesUserType) => void;
  cleanFuneralHomes: () => void;
  refreshFuneralHomes: () => void;
  isLoading: boolean;
  canAccessFuneralContext: boolean;
}

const initialState: InitialValueProps = {
  funeralHomesUser: {
    selectedFuneralHomesUser: '',
    funeralHomesOptions: [],
    selectedFuneralData: {} as FuneralHomesResponse,
    allFuneralHomesData: [],
  },
  setFuneralHomesUser: (data: funeralHomesUserType) => {},
  cleanFuneralHomes: () => {},
  refreshFuneralHomes: () => {},
  isLoading: false,
  canAccessFuneralContext: false,
};

const FuneralHomesUserContext = createContext<InitialValueProps>(initialState);

interface FuneralHomesProviderProps {
  children: ReactNode;
}

type FuneralHomeUserParam = {
  selectedFuneralHomesUser: string;
  selectedFuneralData: FuneralHomesResponse;
  allFuneralHomesData: FuneralHomesResponse[];
  funeralHomesOptions: {
    label: string;
    value: string;
  }[];
};

export const FuneralHomesUserProvider = ({
  children,
}: FuneralHomesProviderProps) => {
  const { getEmployeesFuneralHomes } = sisfuner();
  const { setItem, getItem } = useLocalStorage();
  const { user, isMaster } = useSession();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const selectedFuneralHome = getItem('selectedFuneralHomesUser');
  const options = getItem('funeralHomesOptions') ?? '[]';
  const [funeralHomesUser, setFuneralHomesUser] =
    useState<FuneralHomeUserParam>({
      selectedFuneralHomesUser: selectedFuneralHome ?? '',
      funeralHomesOptions: JSON.parse(options || '[]'),
      selectedFuneralData: {} as FuneralHomesResponse,
      allFuneralHomesData: [],
    });

  const canAccessFuneralContext =
    isLoading || funeralHomesUser.allFuneralHomesData.length > 0 || isMaster;

  const handleEmployeesFuneralHomes = async () => {
    try {
      setIsLoading(true);
      const data = await getEmployeesFuneralHomes(user.email);
      const allFuneralHomesData = data?.['funeral-homes'];

      if (allFuneralHomesData?.length) {
        const primary = allFuneralHomesData.find((p) => p.isPrimary);
        const current = allFuneralHomesData.find(
          (p) => String(p.id) === String(selectedFuneralHome)
        );

        const funeralHomesOptions = allFuneralHomesData.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        setItem('funeralHomesOptions', JSON.stringify(funeralHomesOptions));
        setFuneralHomesUser({
          ...funeralHomesUser,
          funeralHomesOptions,
          allFuneralHomesData,
          selectedFuneralHomesUser: current?.id ?? primary!.id,
          selectedFuneralData: current || primary!,
        });
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const cleanFuneralHomes = () => {
    setFuneralHomesUser({
      selectedFuneralHomesUser: '',
      funeralHomesOptions: [],
      selectedFuneralData: {} as FuneralHomesResponse,
      allFuneralHomesData: [],
    });
    setItem('selectedFuneralHomesUser', '');
    setItem('funeralHomesOptions', '[]');
  };

  useEffect(() => {
    if (funeralHomesUser.selectedFuneralHomesUser) {
      setItem(
        'selectedFuneralHomesUser',
        funeralHomesUser.selectedFuneralHomesUser
      );

      const selected = funeralHomesUser.allFuneralHomesData.find(
        (p) => p.id === funeralHomesUser.selectedFuneralHomesUser
      );

      if (selected) {
        setFuneralHomesUser({
          ...funeralHomesUser,
          selectedFuneralData: selected,
        });
      }
    }
  }, [funeralHomesUser.selectedFuneralHomesUser]);

  useEffect(() => {
    if (user.email) {
      handleEmployeesFuneralHomes();
    }
  }, [user.email]);

  return (
    <FuneralHomesUserContext.Provider
      value={{
        funeralHomesUser,
        setFuneralHomesUser,
        cleanFuneralHomes,
        refreshFuneralHomes: handleEmployeesFuneralHomes,
        isLoading,
        canAccessFuneralContext,
      }}
    >
      {children}
    </FuneralHomesUserContext.Provider>
  );
};

export const useFuneralHomesUser = () => useContext(FuneralHomesUserContext);
