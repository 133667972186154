import { Permission } from 'lib/contextual';

class PermissionService {
  getPermission(permission: string) {
    try {
      const permissionsMock: Permission[] = [
        {
          name: 'master',
          rules: {
            home: {
              cards: [
                'cashFlow',
                'associates',
                'management',
                'paymentSlip',
                'deliveryManagement',
                'newClient',
                'tuitionAdjustment',
                'financial',
                'associateCharge',
                'postSelling',
                'graves',
                'managementReports',
                'orthopedicSupport',
                'depositReceipt',
                'registrations',
                'orderServiceSisfuner',
                'newServiceOrderSisfunerDetails',
                'sisfuner',
                'depositReceipt',
              ],
            },
            cashFlow: {
              cards: ['homeCharge'],
            },
            sisfunerCashFlow: {
              cards: ['homeCharge'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              installmentDebts: {
                components: ['deletePayments', 'confirmPayment'],
              },
              changePaymentMethod: {
                components: ['changePayment'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              upsell: {
                components: ['validatesUpsell'],
              },
            },
            management: {
              plans: ['editCemeteries'],
            },
            paymentSlip: {
              cards: ['print', 'management', 'online'],
            },
            deliveryManagement: {},
            newClient: {
              components: ['newClientReport'],
              partnerDetail: { components: ['accordion'] },
              installmentsAndDebts: {
                components: [
                  'createParcel',
                  'receiveParcel',
                  'deleteParcel',
                  'editParcel',
                  'generateBankSlips',
                ],
              },
              editAssociate: { components: ['edit'] },
            },
            tuitionAdjustment: {},
            financial: {
              components: ['installmentTransactions', 'cashReport'],
            },
            associateCharge: {
              components: [
                'billingSchedule',
                'currentCampaign',
                'mySchedules',
                'comissionReport',
                'electronicBilling',
              ],
            },
            postSelling: {},
            graves: {},
            managementReports: {},
            registrations: {},
            orderServiceSisfuner: {},
            newServiceOrderSisfunerDetails: {},
            orthopedicSupport: {
              cards: ['adminOrthopedicSupport'],
            },
            depositReceipt: {},
            sisfunerUnitManager: {
              cards: ['sisfunerUnitManager'],
            },
            sisfuner: {
              cards: ['master'],
            },
            orderServicePayment: {},
          },
        },
        {
          name: 'equipe cobranca',
          rules: {
            home: {
              cards: ['associateCharge'],
            },
            associateCharge: {
              components: [
                'billingSchedule',
                'currentCampaign',
                'mySchedules',
                'comissionReport',
              ],
            },
          },
        },
        {
          name: 'ajuste de mensalidade',
          rules: {
            home: {
              cards: ['tuitionAdjustment'],
            },
            tuitionAdjustment: {},
          },
        },
        {
          name: 'atendente',
          rules: {
            home: {
              cards: [
                'cashFlow',
                'associates',
                'deliveryManagement',
                'newClient',
                'paymentSlip',
                'orthopedicSupport',
              ],
            },
            cashFlow: {
              cards: ['homeCharge'],
            },
            paymentSlip: {
              cards: ['print'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: { components: ['confirmPayment'] },
            },
            deliveryManagement: {},
            newClient: {
              partnerDetail: { components: ['accordion'] },
              installmentsAndDebts: {
                components: [
                  'createParcel',
                  'receiveParcel',
                  'editParcel',
                  'generateBankSlips',
                ],
              },
            },
            orthopedicSupport: {},
          },
        },
        {
          name: 'Atendente - Exclusão',
          rules: {
            home: {
              cards: [
                'cashFlow',
                'associates',
                'deliveryManagement',
                'newClient',
                'paymentSlip',
                'orthopedicSupport',
              ],
            },
            cashFlow: {
              cards: ['homeCharge'],
            },
            paymentSlip: {
              cards: ['print'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: { components: ['confirmPayment'] },
            },
            deliveryManagement: {},
            newClient: {
              partnerDetail: { components: ['accordion'] },
              installmentsAndDebts: {
                components: [
                  'createParcel',
                  'receiveParcel',
                  'deleteParcel',
                  'editParcel',
                  'generateBankSlips',
                ],
              },
            },
            orthopedicSupport: {},
          },
        },
        {
          name: 'atendenteN1',
          rules: {
            home: {
              cards: [
                'cashFlow',
                'associates',
                'deliveryManagement',
                'newClient',
                'paymentSlip',
                'orthopedicSupport',
              ],
            },
            cashFlow: {
              cards: ['homeCharge'],
            },
            paymentSlip: {
              cards: ['print'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: {
                components: ['confirmPaymentRestricted'],
              },
            },
            newClient: {
              partnerDetail: { components: ['accordion'] },
              installmentsAndDebts: {
                components: [
                  'createParcel',
                  'receiveParcel',
                  'editParcel',
                  'generateBankSlips',
                ],
              },
            },
            deliveryManagement: {},
            orthopedicSupport: {},
          },
        },
        {
          name: 'sac',
          rules: {
            home: {
              cards: [
                'associates',
                'deliveryManagement',
                'newClient',
                'graves',
                'orthopedicSupport',
              ],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: {
                components: [],
              },
            },
            deliveryManagement: {},
            newClient: {
              partnerDetail: {
                components: ['completeChanges', 'documentsToSign', 'accordion'],
              },
              installmentsAndDebts: {
                components: [
                  'createParcel',
                  'receiveParcel',
                  'deleteParcel',
                  'editParcel',
                  'sendCharge',
                  'generateBankSlips',
                ],
              },
            },
            graves: {},
            documentsToSign: {},
            orthopedicSupport: {},
          },
        },
        {
          name: 'gerente sac',
          rules: {
            home: {
              cards: [
                'associates',
                'deliveryManagement',
                'newClient',
                'graves',
                'managementReports',
                'orthopedicSupport',
              ],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: {
                components: [],
              },
            },
            deliveryManagement: {},
            newClient: {
              partnerDetail: {
                components: ['completeChanges', 'documentsToSign', 'accordion'],
              },
              installmentsAndDebts: {
                components: [
                  'createParcel',
                  'receiveParcel',
                  'deleteParcel',
                  'editParcel',
                  'sendCharge',
                  'receiveEmployeePayment',
                  'generateBankSlips',
                ],
              },
            },
            graves: {},
            managementReports: {},
            documentsToSign: {},
            orthopedicSupport: {},
          },
        },
        {
          name: 'agente funerario',
          rules: {
            home: {
              cards: ['sisfuner'],
            },
            sisfuner: {},
            sisfunerCashFlow: {
              cards: ['homeCharge'],
            },
            orderServiceSisfuner: {},
            newServiceOrderSisfunerDetails: {},
            sisfunerFuneralAgent: {
              cards: [
                'sisfunerFuneralAgent',
                'sisfunerCashFlow',
                'orderServiceSisfuner',
                'newServiceOrderSisfunerDetails',
              ],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              installmentDebts: {},
              dependentsManagement: { components: [] },
              deathHistory: {},
              fees: {},
              contract: {},
              vaults: {},
            },
            orderServicePayment: {},
          },
        },
        {
          name: 'agente funerario n1',
          rules: {
            home: {
              cards: ['sisfuner'],
            },
            sisfuner: {},
            orderServiceSisfuner: {},
            sisfunerFuneralAgent: {
              cards: ['sisfunerFuneralAgentN1'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              installmentDebts: {},
              dependentsManagement: { components: [] },
              deathHistory: {},
              fees: {},
              contract: {},
              vaults: {},
            },
            orderServicePayment: {},
          },
        },
        {
          name: 'cobranca domicilio',
          rules: {
            home: {
              cards: ['cashFlow', 'associates'],
            },
            cashFlow: {
              cards: ['homeCharge'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: {
                components: ['deletePayments'],
              },
            },
            newClient: {
              partnerDetail: { components: ['accordion'] },
              installmentsAndDebts: {
                components: [
                  'createParcel',
                  'receiveParcel',
                  'deleteParcel',
                  'generateBankSlips',
                ],
              },
            },
          },
        },
        {
          name: 'producao lote novos associados',
          rules: {
            home: {
              cards: ['paymentSlip'],
            },
            paymentSlip: {
              cards: ['print', 'management', 'online'],
            },
          },
        },
        {
          name: 'producao lote reajuste',
          rules: {
            home: {
              cards: ['paymentSlip'],
            },
            paymentSlip: {
              cards: ['print', 'management', 'online'],
            },
          },
        },
        {
          name: 'gestor equipe cobranca',
          rules: {
            home: {
              cards: ['associateCharge'],
            },
            associateCharge: {
              components: [
                'billingSchedule',
                'currentCampaign',
                'mySchedules',
                'comissionReport',
                'electronicBilling',
              ],
            },
          },
        },
        {
          name: 'pos venda',
          rules: {
            home: {
              cards: ['associates', 'paymentSlip', 'postSelling'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: {
                components: ['deletePayments'],
              },
              changePaymentMethod: {
                component: ['changePayment'],
              },
            },
            paymentSlip: {
              cards: ['print'],
            },
            deliveryManagement: {},
            newClient: {
              partnerDetail: { components: ['accordion'] },
              installmentsAndDebts: {
                components: ['deleteParcel', 'generateBankSlips'],
              },
            },
            postSelling: {},
          },
        },
        {
          name: 'financeiro',
          rules: {
            home: {
              cards: ['financial', 'associates'],
            },
            financial: {
              components: ['installmentTransactions', 'cashReport'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: { components: ['confirmPayment'] },
            },
            newClient: {
              installmentsAndDebts: {
                components: ['editParcel', 'generateBankSlips'],
              },
            },
          },
        },
        {
          name: 'apoio ortopédico',
          rules: {
            home: {
              cards: ['cashFlow', 'associates', 'orthopedicSupport'],
            },
            cashFlow: {
              cards: ['homeCharge'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: {
                components: ['deletePayments', 'confirmPayment'],
              },
            },
            orthopedicSupport: {},
          },
        },
        {
          name: 'gestor apoio ortopédico',
          rules: {
            home: {
              cards: [
                'cashFlow',
                'associates',
                'deliveryManagement',
                'newClient',
                'paymentSlip',
                'orthopedicSupport',
              ],
            },
            cashFlow: {
              cards: ['homeCharge'],
            },
            paymentSlip: {
              cards: ['print'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: { components: ['confirmPayment'] },
            },
            deliveryManagement: {},
            newClient: {
              partnerDetail: { components: ['accordion'] },
              installmentsAndDebts: {
                components: ['deleteParcel', 'editParcel', 'generateBankSlips'],
              },
            },
            orthopedicSupport: {
              cards: ['adminOrthopedicSupport'],
            },
            adminOrthopedicSupport: {},
          },
        },
        {
          name: 'cadastro',
          rules: {
            home: {
              cards: [
                'cashFlow',
                'associates',
                'deliveryManagement',
                'paymentSlip',
                'newClient',
              ],
            },
            cashFlow: {},
            paymentSlip: {
              cards: ['print', 'management'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              upsell: {
                components: ['validatesUpsell'],
              },
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: {
                components: [],
              },
            },
            deliveryManagement: {},
            newClient: {
              components: ['newClientReport'],
              partnerDetail: { components: ['accordion'] },
              installmentsAndDebts: {
                components: [
                  'createParcel',
                  'receiveParcel',
                  'deleteParcel',
                  'editParcel',
                  'generateBankSlips',
                ],
              },
              editAssociate: { components: ['edit'] },
            },
          },
        },
        {
          name: 'sangria de caixa',
          rules: {
            home: {
              cards: [
                'cashFlow',
                'associates',
                'deliveryManagement',
                'newClient',
                'paymentSlip',
                'orthopedicSupport',
                'depositReceipt',
              ],
            },
            cashFlow: {
              cards: ['homeCharge'],
            },
            paymentSlip: {
              cards: ['print'],
            },
            associates: {
              observations: {
                components: ['new'],
              },
              form: {
                components: ['edit', 'periodicUpdate'],
              },
              changeHolder: {},
              dependentsManagement: {
                components: ['new', 'edit'],
              },
              makingOfCards: {},
              changePayment: {},
              deathHistory: {},
              addendumCremation: {},
              renegotiateGrace: {},
              cancelPlan: {},
              delivery: {},
              fees: {
                components: ['new', 'edit', 'delete'],
              },
              contract: {},
              contractChangeForm: {},
              vaults: {
                components: ['edit', 'delete'],
              },
              installmentDebts: { components: ['confirmPayment'] },
            },
            deliveryManagement: {},
            newClient: {
              partnerDetail: { components: ['accordion'] },
              installmentsAndDebts: {
                components: [
                  'createParcel',
                  'receiveParcel',
                  'editParcel',
                  'generateBankSlips',
                ],
              },
            },
            orthopedicSupport: {},
            depositReceipt: {},
          },
        },
        {
          name: 'vendedor',
          rules: {},
        },
        {
          name: 'analista',
          rules: {},
        },
        {
          name: 'cadastros Sisfuner',
          rules: {
            home: {
              cards: ['registrations'],
            },
            registrations: {},
          },
        },
        {
          name: 'Ordem de serviço Sisfuner',
          rules: {
            home: {
              cards: ['orderServiceSisfuner'],
            },
            sisfuner: {},
            orderServiceSisfuner: {},
            newServiceOrderSisfunerDetails: {},
          },
        },
        {
          name: 'gestor de unidade sisfuner',
          rules: {
            home: {
              cards: ['sisfuner'],
            },
            sisfunerUnitManager: {
              cards: [
                'sisfunerUnitManager',
                'orderServiceSisfuner',
                'newServiceOrderSisfunerDetails',
              ],
            },
            sisfuner: {},
            orderServiceSisfuner: {},
            newServiceOrderSisfunerDetails: {},
            sisfunerCashFlow: {
              cards: ['homeCharge'],
            },
            associates: {},
            orderServicePayment: {},
          },
        },
        {
          name: 'vendedor',
          rules: {},
        },
        {
          name: 'analista',
          rules: {},
        },
      ];
      const arrayPerm = permissionsMock.filter(
        ({ name }) => name === permission
      );
      if (arrayPerm.length === 0) throw new Error('Permission not found');
      return arrayPerm[0];
    } catch (err) {
      throw err;
    }
  }
}
export default new PermissionService();
