import * as Popover from '@radix-ui/react-popover';
import styled, { css } from 'styled-components';

import Divider from 'components/shared/core/divider';
import SearchSelect from 'components/shared/forms/SearchSelect';

import { device } from 'styles/tokens/devices';

export const Container = styled.div`
  width: 100%;
  height: 10vh;
  min-height: 100vh;
  background-color: ${(props) => props.theme.surface.header};
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;
export const Header = styled.header`
  width: 100%;
  padding: 0.8rem;
  padding-inline: 8% 8%;
  @media screen and (max-width: 520px) {
    padding-inline: 2rem;
  }
  display: flex;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 8rem;
  @media screen and (max-width: 520px) {
    width: 5rem;
    padding: 1rem 0;
    padding-inline: 0 1rem;
  }
`;

export const Content = styled.main`
  background-color: hsla(235, 32%, 93%, 1);
  flex: 1;
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  height: 100%;
`;

export const AsideHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  & article p {
    text-align: right;
    font-size: ${(props) => props.theme.fontSizes.small1};
    line-height: ${(props) => props.theme.fontSizes.medium};
    color: #fff;
    display: block;
  }

  & article span {
    font-size: ${(props) => props.theme.fontSizes.small1};
    line-height: ${(props) => props.theme.fontSizes.small1};
    font-weight: 600;
    color: ${(props) => props.theme.primary.dark};
  }

  @media screen and (max-width: 1050px) {
    article span {
      font-size: ${(props) => props.theme.fontSizes.xs};
    }

    figure {
      display: none;
    }
  }
`;

export const Actions = styled.div``;

export const Link = styled.button`
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: ${(props) => props.theme.fontSizes.small};
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};

  &:hover {
    color: ${(props) => props.theme.primary.dark};
  }

  &:active {
    opacity: 0.7;
  }

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

export const CartWrapper = styled(Popover.Root)``;
export const BellWrapper = styled(Popover.Root)``;
export const PopoverTrigger = styled(Popover.Trigger)<{ $isLoading: boolean }>`
  ${({ theme, $isLoading }) =>
    css`
      cursor: pointer;
      background: transparent;
      border: none;
      position: relative;

      span {
        width: 20px;
        height: 20px;
        border-radius: ${theme.borderRadius.sm};
        background: ${!$isLoading && theme.primary.main};
        position: absolute;
        top: 0;
        right: 0;

        &::after {
          border-radius: ${theme.new.shapes.borderRadius.full};
        }
      }
    `}
`;

export const PopoverContent = styled(Popover.Content)`
  ${({ theme }) => css`
    background: ${theme.surface.main};
    position: relative;
    padding: ${theme.spacing.sm1} 20px;
    border-radius: ${theme.borderRadius.md};
    min-width: 250px;
    min-height: 106px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      background: ${theme.surface.main};
      width: 14px;
      height: 14px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: rotate(45deg) translateX(-50%);
    }

    ul {
      list-style: none;
      width: 100%;
    }
  `}
`;

export const CardItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    div:first-child {
      display: flex;
      flex-direction: column;
      margin-right: ${theme.spacing['lg2.5']};

      p {
        font-size: ${theme.fontSizes.xs};
        line-height: 18px;
        text-transform: uppercase;
        color: ${theme.texts.options};
      }

      p:first-child {
        color: ${theme.texts.lightSecondary};
        font-weight: bold;
      }
    }

    div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      svg {
        width: 32px;
        height: 32px;

        path {
          stroke: ${theme.texts.options};
        }
      }

      span {
        width: 20px;
        height: 20px;
        border-radius: ${theme.borderRadius.sm};
        background: ${theme.primary.main};
        position: absolute;
        top: 0;
        right: -6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${theme.fontSizes.xs};
        line-height: 17px;
      }
    }
  `}
`;
export const NotificationItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    div:first-child {
      display: flex;
      flex-direction: column;
      margin-right: ${theme.spacing['lg2.5']};

      p {
        font-size: ${theme.fontSizes.xs};
        line-height: 18px;
        text-transform: uppercase;
        color: ${theme.texts.options};
      }

      p:first-child {
        color: ${theme.texts.lightSecondary};
        font-weight: bold;
      }
    }

    div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      svg {
        width: 20px;
        height: 20px;

        path {
          stroke: ${theme.texts.options};
        }
      }

      span {
        width: 20px;
        height: 20px;
        border-radius: ${theme.borderRadius.sm};
        background: ${theme.primary.main};
        position: absolute;
        top: 0;
        right: -6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${theme.fontSizes.xs};
        line-height: 17px;
      }
    }
  `}
`;
export const ItemDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing.sm} 0;
`;

export const EmptyCartMessage = styled.span`
  ${({ theme }) => css`
    line-height: 18px;
    font-size: ${theme.fontSizes.xs};
    color: ${theme.texts.thinParagraph};
  `}
`;

export const ContainerHeader = styled.div`
  ${({ theme }) => css`
    #funeralHomeSelectId {
      min-width: 300px;
      max-height: 48px;
      background: transparent;
      border: 1px solid ${theme.new.colors.neutrals[600]};

      button {
        color: ${theme.new.colors.neutrals[0]};
      }
    }

    #search-input-funeralHomeSelected {
      color: ${theme.texts.options};
    }
  `}

  @media ${device.tabletL} {
    display: none;
  }
`;

export const ContainerHeaderMobile = styled.div`
  ${({ theme }) => css`
    #funeralHomeSelectId {
      min-width: 300px;
      max-height: 48px;
      background: transparent;
      border: 1px solid ${theme.new.colors.neutrals[600]};

      button {
        color: ${theme.new.colors.neutrals[0]};
      }
    }

    #search-input-funeralHomeSelected {
      color: ${theme.texts.options};
    }
  `}

  @media ${device.mobileL} {
    display: none;
  }
  padding: ${({ theme }) => theme.spacing.xs};
`;

export const SelectHeader = styled(SearchSelect)``;
